@import "global";

.workpocket {
  $this: &;
  z-index: 3;

  &__letter {
    fill: rgba(#231f20, 0.1);
    max-width: 620px;
    left: 55px;
  }

  &__copy {
    max-width: 500px;
  }

  .citrus {
    top: auto;
    right: -100px;
    bottom: 15px;
  }

  .section__cta {
    background-color: #65a6c7;
    &:hover {
      background-color: darken(#65a6c7, 5);
    }

    .arrow path {
      fill: #a4d0e6;
    }

    &:after {
      background-color: #65a6c7;
    }

  }

}